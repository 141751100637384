<template>
  <page-layout
    :title="title"
    :sub-title="$t('admin_management.view_admin_page.subtitle')"
    :has-back-button="true"
    :emit-back="true"
    @back="back"
  >
    <template #header-actions>
      <base-button
        v-if="showSaveChanges"
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="$refs.routerView.saving"
        :disabled="$refs.routerView.saveDisabled"
        @click="$refs.routerView.handleSaveChanges"
      />
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span"><i v-if="tab.icon" :class="tab.icon"></i> {{tab.label}}</span>
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView"/>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapGetters } from 'vuex'
import ViewAppUser from '../mixins/ViewAppUser'
import TabNavigation from '../mixins/TabNavigation'

export default {
  name: 'AdminUserViewLayout',
  components: {
    PageLayout
  },
  mixins: [ViewAppUser, TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeName: '',
      showSaveChanges: false
    }
  },
  methods: {
    back () {
      return this.$router.push({
        name: 'ManageAdmins'
      })
    },
    showButtons () {
      this.showSaveChanges = !!this.$route.meta.showSaveChangesButton
    }
  },
  computed: {
    ...mapGetters({
      employeeName: 'user/employeeName'
    }),
    userOspId () {
      return this.$route.params.userOspId || ''
    },
    title () {
      return this.userOspId && this.employeeName(this.userOspId) ? this.employeeName(this.userOspId) : ''
    },
    tabs () {
      return [
        {
          label: this.$t('user_management.view_app_users_page.tab_label.details'),
          name: 'AdminUserViewDetails',
          icon: 'ri-money-euro-circle-line'
        },
        {
          label: this.$t('general.label.roles'),
          name: 'AdminUserViewRoles',
          icon: 'ri-arrow-left-right-line'
        }
      ]
    }
  }
}
</script>

<style lang="scss">

</style>
