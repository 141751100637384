<template>
  <el-form
    ref="form"
    :model="form"
    @submit.prevent.native="null"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    v-loading="loading"
  >
    <account-status-section
      v-model="form.userData"
      id="accountSection"
      ref="accountSection"
      :disabled-login="true"
      :show-unblock-user="isShowUnblockUserButton"
      :show-reset-two-factor-auth="isVisibleResetTwoFactorAuthButton"
      :show-enable-two-factor-auth="!(isAdmin || isOspUser)"
      @deactivate="showDeactivationModal"
      @activate="activate"
      @sendPincode="sendPincode"
      @unblockUser="unblockUser"
      @resetTwoFactorAuth="handleReset2FactorAuth"
      @changeDate="changeDate"
    />
    <personal-section
      v-model="form.userData"
      id="personalSection"
      ref="personalSection"
      :extra-button="false"
      :show-extra="true"
      :showBirthdate="false"
    />
    <extra-section v-model="form.userData" />
    <address-section id="homeAddressSection" v-model="form.userData" ref="homeAddressSection" type="home" />
    <address-section v-model="form.userData" id="workAddressSection" type="work" ref="workAddressSection" />
    <change-status-modal ref="changeStatusModal"></change-status-modal>
  </el-form>
</template>

<script>
import { get, cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { confirmDefaultOptions } from '@/app/helpers'
import ExtraSection from '../components/sections/ExtraSection'
import AddressSection from '../components/sections/AddressSection'
import PersonalSection from '../components/sections/PersonalSection'
import ChangeStatusModal from '@/components/ViewAppUser/ChangeStatusModal'
import AccountStatusSection from '../components/sections/AccountStatusSection'
import ViewAppUser from '../mixins/ViewAppUser'
import ConfirmBeforeRouterLeave from '../mixins/ConfirmBeforeRouterLeave'
import Role from '@/app/services/RolesService/Role'
import UserRepository from '@/app/repositories/UserRepository'

const userRepository = new UserRepository()

export default {
  name: 'AdminUserViewDetails',
  components: {
    AccountStatusSection,
    PersonalSection,
    ExtraSection,
    AddressSection,
    ChangeStatusModal
  },
  mixins: [ViewAppUser, ConfirmBeforeRouterLeave],
  props: ['userOspId'],
  data () {
    return {
      form: {
        userData: {},
        ospId: null
      },
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false,
      original: null
    }
  },
  computed: {
    ...mapGetters({
      selectedCorporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    username () {
      return get(this.form, ['userData', 'firstname'], '') + ' ' + get(this.form, ['userData', 'lastname'], '')
    },
    isShowUnblockUserButton () {
      return this.form.userData.failedLoginCount >= 3
    },
    isMyOwnAccount () {
      return this.$auth.user().id() === this.userOspId
    },
    userRoles () {
      return get(this.form, ['userData', 'roles'], []) || []
    },
    isAdmin () {
      return this.userRoles.includes(Role.OSPADMIN)
    },
    isOspUser () {
      return this.userRoles.includes(Role.OSPUSER)
    },
    isVisibleResetTwoFactorAuthButton () {
      const isHavingTwoFactorAuth = get(this.original, ['requires2fa'], false)
      const isSelectedCAHaveTwoFactorAuth = get(
        this.selectedCorporateAccount,
        ['corporateAccount', 'data', 'requires2fa'],
        false
      )
      return !this.isMyOwnAccount && (this.isAdmin || isHavingTwoFactorAuth || isSelectedCAHaveTwoFactorAuth)
    }
  },
  methods: {
    ...mapActions({
      getEmployeeName: 'user/getEmployeeName'
    }),
    async activate () {
      try {
        await this.$confirm(
          this.$t('user_management.view_app_users_page.details.confirm_activate'),
          confirmDefaultOptions
        )
        this.loading = true
        await this.$api.user.activate(this.userOspId)
        await this.fetchData()
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    showDeactivationModal () {
      const title = this.$t('user_management.view_app_users_page.details.confirm_deactivate_title', {
        username: this.username
      })
      const description = this.$t('user_management.view_app_users_page.details.confirm_deactivate_explanation_admin', {
        username: this.username
      })
      this.$refs.changeStatusModal.show({
        title,
        description,
        buttonType: 'danger',
        buttonLabel: this.$t('user_management.view_app_users_page.details.confirm__submit_deactivate', {
          username: this.username
        }),
        cb: this.deactivationModalHandler,
        isDeactivation: true,
        swapLabel: true
      })
    },
    changeDate () {
      if (this.form.userData.activationStatus === 'ACTIVE' && this.form.userData.desiredDeactivationDate) {
        this.$refs.changeStatusModal.show({
          isDeactivation: true,
          date: this.form.userData.desiredDeactivationDate,
          cb: date => {
            if (date !== this.form.userData.desiredDeactivationDate) {
              this.form.userData.desiredDeactivationDate = date
              this.handleSaveChanges()
            }
          }
        })
      }
      if (this.form.userData.activationStatus === 'INACTIVE' && this.form.userData.desiredActivationDate) {
        this.$refs.changeStatusModal.show({
          swapLabel: true,
          date: this.form.userData.desiredActivationDate,
          cb: date => {
            if (date !== this.form.userData.desiredActivationDate) {
              this.form.userData.desiredActivationDate = date
              this.handleSaveChanges()
            }
          }
        })
      }
    },
    deactivationModalHandler (date) {
      if (date) {
        this.form.userData.desiredDeactivationDate = date
        return this.handleSaveChanges()
      }
      return this.deactivate()
    },
    async deactivate () {
      this.loading = true
      try {
        await this.$api.user.deactivate(this.userOspId)
        await this.fetchData()
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    async sendPincode () {
      try {
        await this.$confirm(
          this.$t('user_management.view_app_users_page.details.confirm_send_pincode'),
          confirmDefaultOptions
        )
        this.loading = true
        await this.$api.user.sendPincode(this.userOspId, {
          successNotification: this.$t('user_management.view_app_users_page.details.send_pincode_success')
        })
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    unblockUser () {
      return this.$api.user.unblock(this.userOspId, {
        onSuccess: () => {
          return this.fetchData()
        },
        successNotification: this.$t('general.notification.user.userUnblock')
      })
    },
    setData (data) {
      this.form = data
      this.original = cloneDeep(data?.userData)
    },
    fetchData () {
      this.loading = true
      return userRepository.getUser(this.userOspId, {
        onSuccess: response => {
          this.setData(response)
          this.saveDisabled = false
          this.key = this.$key()
          setTimeout(() => {
            this.dirty = false
          }, 100)
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    handleSaveChanges () {
      if (!this.validate()) {
        return false
      }
      this.saving = true
      this.$api.user
        .updateUser(
          {
            ospId: this.form.ospId,
            userData: {
              ...this.form.userData
            }
          },
          {
            successNotification: this.$t('user_management.view_app_users_page.details.success_notification_save'),
            onSuccess: async () => {
              const updatedData = await this.getEmployeeName(this.form.ospId)
              this.setData(updatedData)
            },
            onFinally: () => {
              this.saving = false
              this.dirty = false
            }
          }
        )
        .catch(e => {
          console.log(e)
          this.dirty = false
          this.saving = false
        })
    },
    validate () {
      this.$refs.personalSection.$v.form.$touch()
      this.$refs.homeAddressSection.$v.form.$touch()
      this.$refs.workAddressSection.$v.form.$touch()
      if (this.$refs.personalSection.$v.form.$invalid) {
        this.$scrollTo('#personalSection')
        return false
      }
      if (this.$refs.homeAddressSection.$v.form.$invalid) {
        this.$scrollTo('#homeAddressSection')
        return false
      }
      if (this.$refs.workAddressSection.$v.form.$invalid) {
        this.$scrollTo('#workAddressSection')
        return false
      }
      return true
    },
    handleReset2FactorAuth () {
      this.$api.user.reset2FactorAuth(this.form.ospId, {
        onSuccess: () => {
          this.fetchData()
        },
        successNotification: this.$t('general.notification.user.reset_two_factor_auth')
      })
    }
  },
  async mounted () {
    await this.fetchData()
    this.$watch('form', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>

<style lang="scss"></style>
