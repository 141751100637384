<template>
  <page-layout
    :title="username"
    :has-back-button="true"
    :sub-title="getSubtitle"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :label="$t('general.button_label.save_changes')"
        :loading="saving"
        :disabled="loading"
        @click="handleSaveChanges"
      />
    </template>

    <el-alert
      type="info"
      :closable="false"
      show-icon>
      <span v-html="getAlertText">
      </span>
    </el-alert>
    <el-form ref="form" :model="model" @submit.prevent.native="null" :key="key" autocomplete="off" v-loading="loading">
      <account-status-section
        v-model="model.userData"
        id="accountSection"
        ref="accountSection"
        :show-login="false"
        :show-resend-credentials="false"
        @deactivate="deactivate"
        @activate="activate"
      />
      <personal-section
        v-model="model.userData"
        id="personalSection"
        ref="personalSection"
        :isFirstNameRequired='false'
        :isLastNameRequired='false'
        :isPhoneRequired='false'
      ></personal-section>
    </el-form>
  </page-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PageLayout from '../layouts/PageLayout'
import PersonalSection from '../components/sections/PersonalSection'
import UserRepository from '../app/repositories/UserRepository'
import ViewAppUser from '@/mixins/ViewAppUser'
import { confirmDefaultOptions } from '@/app/helpers'
import AccountStatusSection from '@/components/sections/AccountStatusSection'

const userRepository = new UserRepository()

export default {
  name: 'ContactUserView',
  components: {
    AccountStatusSection,
    PageLayout,
    PersonalSection
  },
  props: ['userOspId'],
  mixins: [ViewAppUser],
  data () {
    return {
      key: '',
      loading: false,
      saving: false,
      saveDisabled: false,
      model: {
        userData: {
          firstname: '',
          lastname: '',
          email: '',
          msisdn: '',
          language: 'nl',
          contactTypes: []
        }
      }
    }
  },
  computed: {
    ...mapGetters({}),
    getSubtitle () {
      switch (this.model.userData.contactTypes[0]) {
        case 'BUDGET': {
          return this.$t('admin_management.add_admin_mobility_view_page.subtitle')
        }
        case 'HUMAN_RESOURCES': {
          return this.$t('admin_management.admin_HR_view_page.subtitle')
        }
        default: {
          return this.$t('admin_management.admin_invoicing_view_page.subtitle')
        }
      }
    },
    getAlertText () {
      switch (this.model.userData.contactTypes[0]) {
        case 'BUDGET': {
          return this.$t('admin_management.admin_mobility_view_page.alert_text')
        }
        case 'HUMAN_RESOURCES': {
          return this.$t('admin_management.admin_HR_view_page.alert_text')
        }
        default: {
          return this.$t('admin_management.admin_invoicing_view_page.alert_text')
        }
      }
    }
  },

  methods: {
    ...mapActions({
      getEmployeeName: 'user/getEmployeeName'
    }),
    async activate () {
      try {
        await this.$confirm(this.$t('user_management.view_app_users_page.details.confirm_activate'), confirmDefaultOptions)
        this.loading = true
        await this.$api.user.activate(this.userOspId)
        await this.fetchData()
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async deactivate () {
      try {
        await this.$confirm(this.$t('user_management.view_app_users_page.details.confirm_deactivate_explanation_admin', { username: this.username }),
          this.$t('user_management.view_app_users_page.details.confirm_deactivate_title', { username: this.username }),
          {
            ...confirmDefaultOptions,
            confirmButtonText: this.$t('user_management.view_app_users_page.details.confirm__submit_deactivate', { username: this.username }),
            confirmButtonClass: 'base-button el-button--danger'
          })
        this.loading = true
        await this.$api.user.deactivate(this.userOspId)
        await this.fetchData()
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    fetchData () {
      this.loading = true
      return userRepository.getUser(this.userOspId, {
        onSuccess: response => {
          this.model = response
          this.saveDisabled = false
          this.key = this.$key()
          setTimeout(() => {
            this.dirty = false
          }, 100)
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    handleSaveChanges () {
      if (!this.validate()) {
        return false
      }
      this.saving = true
      this.model.update({
        successNotification: this.$t('user_management.view_app_users_page.details.success_notification_save'),
        onSuccess: () => {
          this.getEmployeeName(this.model.ospId)
        },
        onFinally: () => {
          this.saving = false
          this.dirty = false
        }
      }).catch((e) => {
        console.log(e)
        this.dirty = false
        this.saving = false
      })
    },
    validate () {
      this.$refs.personalSection.$v.form.$touch()
      if (this.$refs.personalSection.$v.form.email.$invalid) {
        this.$scrollTo('#personalSection')
        return false
      }
      return true
    }
  },
  async mounted () {
    await this.fetchData()
    this.$watch('model.userData', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>

<style lang="scss">

</style>
