<template>
  <page-layout
    :title="$t('admin_management.add_admin_page.title')"
    :has-back-button="true"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :label="$t('admin_management.add_admin_page.button_create')"
        :loading="saving"
        @click="submit"
      />
    </template>

    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('admin_management.add_admin_page.alert_text')"></span>
    </el-alert>
    <el-form ref="form" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off">
      <personal-section
        v-model="form.personalForm"
        :showBirthdate="false"
        id="personalSection"
        ref="personalSection"
        @submit="submit"
      />
      <account-section
        ref="account"
        id="accountSection"
        v-model="form.accountForm"
        :extra-button="false"
        @submit="submit"
      />
      <two-factor-auth-section v-model="form.twoFactor"> </two-factor-auth-section>
    </el-form>
  </page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageLayout from '../layouts/PageLayout'
import PersonalSection from '../components/sections/PersonalSection'
import AccountSection from '../components/sections/AccountSection'
import TwoFactorAuthSection from '../components/sections/TwoFactorAuthSection'
import UserRepository from '../app/repositories/UserRepository'

const userRepository = new UserRepository()

export default {
  name: 'AdminUserAdd',
  components: {
    PageLayout,
    PersonalSection,
    AccountSection,
    TwoFactorAuthSection
  },
  data () {
    return {
      key: '',
      saving: false,
      form: {
        personalForm: {
          firstname: '',
          lastname: '',
          email: '',
          msisdn: '',
          language: 'nl'
        },
        twoFactor: false,
        accountForm: {
          login: '',
          desiredActivationDate: null,
          desiredDeactivationDate: null
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    })
  },

  methods: {
    ...mapActions({}),
    validateForms () {
      this.$refs.personalSection.$v.form.$touch()
      if (this.$refs.personalSection.$v.form.$invalid) {
        this.$scrollTo('#personalSection')
        return false
      }
      return true
    },
    async verifyLogin () {
      if (!this.form.accountForm.login) {
        return true
      }
      const { exists } = await this.$api.user.verifyLogin(this.form.accountForm.login)
      if (exists) {
        this.$refs.account.showLoginExistsError()
        this.$scrollTo('#accountSection')
        // accountSection
        return false
      }
      return true
    },
    async submit () {
      // this.$scrollTo('#budgetsSection')
      if (!this.validateForms() || !(await this.verifyLogin())) {
        return false
      }
      this.saving = true
      const form = {
        requires2fa: this.form.twoFactor,
        // login: this.form.login,
        ...this.form.accountForm,
        ...this.form.personalForm,
        corporateAccount: {
          ospId: this.corporateAccount.ospId
        }
      }
      return userRepository.createManager(form, {
        successNotification: this.$t('admin_management.add_admin_page.success_notification'),
        onSuccess: () => {
          this.$router.push({
            name: 'ManageAdmins'
          })
        },
        onFinally: () => {
          this.saving = false
        }
      })
    }
  }
}
</script>
