<template>
  <base-section
    :title="$t('user_management.section.two_factor.title')"
    :key="key"
    class="account-section"
  >
    <base-checkbox
      :label="$t('user_management.section.two_factor.checkbox')"
      v-model="model"
      :explanation="$t('user_management.section.two_factor.checkbox_explanation')"
    />
  </base-section>
</template>
<script>
export default {
  name: 'TwoFactorAuthSection',
  data () {
    return {
      key: ''
    }
  },
  props: {
    value: {
      type: Boolean
    }
  },
  computed: {
    model: {
      get () {
        return !!this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  mounted () {
    this.key = this.$key()
  }
}
</script>

<style scoped>

</style>
