<template>
  <page-layout
    :title="$t('admin_management.view_all_admins_page.title')"
    :sub-title="$t('admin_management.view_all_admins_page.sub_title')"
    :fixed-footer="true"
  >
    <template #header-actions>
      <el-dropdown trigger="click">
        <base-button
          type="primary"
          append-icon="ri-arrow-down-s-line"
          prepend-icon="ri-add-line"
          :label="$t('admin_management.view_all_admins_page.button_add_admin')"
        />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            @click.native="$router.push({name: 'AdminUserAdd'})"
          >
            <i class="ri-user-settings-line"></i>
            {{ $t('admin_management.view_all_admins_page.button_add_admin') }}
          </el-dropdown-item>
          <el-dropdown-item
            @click.native="$router.push({name: 'ContactUserAdd',query:{typeContact:'invoice',type:'ACCOUNTING'}})"
          >
            <i class="ri-user-settings-line"></i>
            {{ $t('admin_management.view_all_admins_page.button_add_invoicing') }}
          </el-dropdown-item>
          <el-dropdown-item
            @click.native="$router.push({name: 'HRContactUserAdd',query:{typeContact:'HR',type:'HUMAN_RESOURCES'}})"
          >
            <i class="ri-user-settings-line"></i>
            {{ $t('admin_management.view_all_admins_page.button_add_hr') }}
          </el-dropdown-item>
          <el-dropdown-item
            @click.native="$router.push({name: 'MobilityContactUserAdd',query:{typeContact:'MobilityBudget',type:'BUDGET'}})"
          >
            <i class="ri-user-settings-line"></i>
            {{ $t('admin_management.view_all_admins_page.button_add_mobility') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <div class="filters">
      <el-form ref="form" :inline="true" :model="form" @submit.prevent.native="fetchData">
        <base-input
          class="search"
          ref="search"
          prop="search"
          v-model.trim="form.search"
          name="search"
          auto-complete="off"
          :placeholder="$t('user_management.manage_app_users_page.form_filter.input_search.label')"
        >
          <template #prependInput>
            <i class="ri-search-line"></i>
          </template>
        </base-input>
        <el-form-item>
          <el-checkbox v-model="form.inactive">
            {{$t('user_management.manage_app_users_page.form_filter.checkbox_inactive.label')}}
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      id="appUsersTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="pagination.sort"
      row-class-name="table-row clickable"
      @row-click="rowClick"
      @sort-change="sortChange"
      style="width: 100%">
      <el-table-column
        prop="icon"
        width="37">
        <template>
          <i class="ri-user-settings-line"></i>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="firstname"
        :label="$t('general.label.first_name')"
        min-width="110"
        show-overflow-tooltip
        sortable="sortColumn">
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="lastname"
        show-overflow-tooltip
        :label="$t('general.label.last_name')"
        min-width="110"
        sortable="custom">
      </el-table-column>
      <el-table-column
        prop="login"
        sortable="sortColumn"
        min-width="130"
        :label="$t('user_management.manage_app_users_page.table_header.login')">
      </el-table-column>
      <el-table-column
        v-if="$auth.isOspAdmin()"
        prop="extId"
        show-overflow-tooltip
        :label="$t('general.label.extid')"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="email"
        sortable="sortColumn"
        min-width="250"
        :label="$t('user_management.manage_app_users_page.table_header.email')">
      </el-table-column>
      <el-table-column
        prop="type"
        show-overflow-tooltip
        :label="$t('general.label.type')"
        width="140">
      </el-table-column>
      <el-table-column
        fixed="right"
        width="80">
        <template slot-scope="{row}">
          <base-button
            @click.stop="rowClick(row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          />
        </template>
      </el-table-column>
    </el-table>

    <template #footer v-if="totalPages > 1">
      <base-pagination
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import UserRepository from '@/app/repositories/UserRepository'
import CancelRequest from '@/mixins/CancelRequest'
import TableLoader from '@/mixins/TableLoader'
import Role from '@/app/services/RolesService/Role'

const defaultSort = { order: 'ascending', prop: 'firstname' }
const userRepository = new UserRepository()

export default {
  name: 'ManageAdmins',
  components: {
    PageLayout
  },
  mixins: [CancelRequest, TableLoader],
  data () {
    return {
      dirtyData: {
        content: [],
        totalPages: 1
      },
      loading: false,
      form: {
        search: '',
        inactive: false
      },
      pagination: {
        page: 0,
        size: 10,
        sort: defaultSort
      },
      requestSource: null
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    'pagination.sort': debounce(function () {
      this.fetchData()
    }, 200),
    'form.search': debounce(function () {
      this.fetchData()
    }, 700),
    'form.inactive': debounce(function () {
      this.fetchData()
    }, 200)
  },
  computed: {
    ...mapGetters({
      email: 'user/email',
      contactTypes: 'user/contactTypes',
      roles: 'user/roles',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    filters () {
      return {
        active: !this.form.inactive,
        pagination: {
          page: this.pagination.page,
          size: this.pagination.size,
          sort: {
            sortOrders: [
              {
                direction: (this.pagination.sort.order || defaultSort.order).toUpperCase(),
                property: this.pagination.sort.prop
              }
            ]
          }
        },
        roleNotIn: [Role.OSPUSER],
        search: this.form.search || undefined,
        corporateAccountOspId: this.corporateAccount.ospId
      }
    },
    tableData () {
      // return []
      return this.dirtyData.content || []
    },
    totalPages () {
      try {
        return this.dirtyData.totalPages || 1
      } catch (e) {
        return 1
      }
    }
  },
  methods: {
    ...mapActions({
      getRolesList: 'user/getRolesList',
      getContactTypes: 'user/getContactTypes'
    }),
    async fetchData () {
      this.TableLoader_show()
      try {
        await userRepository.findByAny(this.filters, {
          cancelToken: this.CancelRequest_token(),
          onSuccess: response => {
            this.dirtyData = response
          }
        })
      } catch (e) {
        console.error(e)
      }
      this.TableLoader_hide()
      return true
    },
    handleEdit ({ ospId }) {
      this.$router.push({
        name: 'AppUserDetailView',
        params: { userOspId: ospId }
      })
    },
    rowClick (user) {
      user.viewDetails()
    },
    sortChange (sort) {
      // console.log(sort)
      this.pagination.sort = sort
    }
  },
  async mounted () {
    this.TableLoader_show()
    const promises = []
    if (!this.contactTypes.length) {
      promises.push(this.getContactTypes())
    }
    if (!this.roles.length) {
      promises.push(this.getRolesList())
    }
    await Promise.all(promises)
    return this.fetchData()
    // this.$api.user.roleList(undefined)
  }
}
</script>
