<template>
  <page-layout
    :title="getTitle"
    :has-back-button="true"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :label="getCreateButtonText"
        :loading="saving"
        @click="submit"
      />
    </template>

    <el-alert
      type="info"
      :closable="false"
      show-icon>
      <span v-html="getAlertText"></span>
    </el-alert>
    <el-form ref="form" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off">
      <base-section
        :title="$t('user_management.section.personal.title')"
        :is-flex="true"
      >
        <el-row :gutter="24">
          <el-col :span="12"
          >
            <base-input
              :label="$t('general.label.first_name')"
              ref="firstname"
              prop="firstname"
              v-model="form.firstname"
              name="firstname"
            />
          </el-col>
          <el-col :span="12">
            <base-input
              :label="$t('general.label.last_name')"
              ref="lastname"
              prop="lastname"
              v-model="form.lastname"
              name="lastname"
            />
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('general.label.email_address')"
              ref="email"
              prop="email"
              v-model="form.email"
              :validations="$v.form.email"
              :required="true"
              name="email"
            />
          </el-col>
          <el-col :span="12">
            <base-tel-input
              :label="$t('general.label.mobile_phone')"
              ref="phone"
              prop="phone"
              placeholder="0476 12 34 56"
              v-model="form.msisdn"
              :validations="$v.form.msisdn"
              name="phone"
            />
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              v-model="form.language"
              :label="$t('general.label.language')"
              :items="languages"
            >
            </base-select>
          </el-col>
        </el-row>
      </base-section>
    </el-form>
  </page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { email, required } from 'vuelidate/lib/validators'
import { telNumber } from '@/app/helpers/validate'
import UserRepository from '../app/repositories/UserRepository'
import PageLayout from '../layouts/PageLayout'

const userRepository = new UserRepository()

export default {
  name: 'ContactUserAdd',
  components: {
    PageLayout
  },
  data () {
    return {
      key: '',
      typeKey: '1',
      saving: false,
      defaultTypes: ['ACCOUNTING', 'BUDGET', 'HUMAN_RESOURCES'],
      form: {
        firstname: '',
        lastname: '',
        email: '',
        msisdn: '',
        language: 'nl',
        contactTypes: ''
      },
      contactType: '',
      typeContact: ''
    }
  },
  validations () {
    return {
      form: {
        email: {
          required,
          email
        },
        msisdn: {
          telNumber: telNumber(this.$refs, 'phone')
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      languages: 'i18n/languages',
      langDefault: 'i18n/langDefault',
      corporateAccount: 'corporateAccount/selectedCorporateAccount',
      contactTypes: 'user/contactTypes'
    }),
    formattedContactTypes () {
      return this.contactTypes
        .filter(type => this.defaultTypes.find(x => x === type.contactType))
        .map(type => {
          return {
            value: type.contactType,
            label: this.$trans(type.label)
          }
        })
    },
    getTitle () {
      switch (this.typeContact) {
        case 'MobilityBudget': {
          return this.$t('admin_management.add_admin_mobility_page.title')
        }
        case 'HR': {
          return this.$t('admin_management.add_admin_HR_page.title')
        }
        default: {
          return this.$t('admin_management.add_admin_invoicing_page.title')
        }
      }
    },
    getCreateButtonText () {
      switch (this.typeContact) {
        case 'MobilityBudget': {
          return this.$t('admin_management.add_admin_mobility_page.button_create')
        }
        case 'HR': {
          return this.$t('admin_management.add_admin_HR_page.button_create')
        }
        default: {
          return this.$t('admin_management.add_admin_invoicing_page.button_create')
        }
      }
    },
    getAlertText () {
      switch (this.typeContact) {
        case 'MobilityBudget': {
          return this.$t('admin_management.add_admin_mobility_page.alert_text')
        }
        case 'HR': {
          return this.$t('admin_management.add_admin_HR_page.alert_text')
        }
        default: {
          return this.$t('admin_management.add_admin_invoicing_page.alert_text')
        }
      }
    }
  },
  methods: {
    ...mapActions({}),
    validateForms () {
      this.$v.form.$touch()
      return !this.$v.form.$invalid
    },
    async submit () {
      if (!this.validateForms()) {
        return false
      }
      this.saving = true
      return userRepository.createInvoicing({
        ...this.form,
        msisdn: this.form.msisdn || undefined,
        corporateAccount: {
          ospId: this.corporateAccount.ospId
        }
      }, {
        successNotification: this.$t('admin_management.add_admin_invoicing_page.success_notification'),
        onSuccess: () => {
          this.$router.push({
            name: 'ManageAdmins'
          })
        },
        onFinally: () => {
          this.saving = false
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.typeContact = to.query.typeContact
      vm.form.contactTypes = [to.query.type]
    })
  }
}
</script>
