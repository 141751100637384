<template>
  <el-form ref="form" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off" id="appUserForm"
           v-loading="loading">
    <el-alert
      type="info"
      :closable="false"
      class="view-admin__alert"
      show-icon>
      <span v-html="getAlertText"/>
    </el-alert>
    <base-section
      :title="$t('general.label.roles')"
      :is-flex="true"
    >
      <base-transfer
        ref="transfer"
        style="width: 60%"
        :key="key"
        :emit-transfer="true"
        :top-titles="[$t('admin_management.view_admin_page.roles.transfer.left_panel_title'), $t('admin_management.view_admin_page.roles.transfer.right_panel_title')]"
        :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
        filterable
        :with-pagination="true"
        :filter-method="filterMethod"
        :value="userRoles"
        :height="470"
        :fullWidth="true"
        :data="availableRoles"
        @transfer="transfer"
      >
      </base-transfer>
    </base-section>
  </el-form>
</template>

<script>
import { get } from 'lodash'
import ViewAppUser from '../mixins/ViewAppUser'
import UserRepository from '@/app/repositories/UserRepository'

const userRepository = new UserRepository()

export default {
  name: 'AdminUserViewRoles',
  components: {},
  mixins: [ViewAppUser],
  props: ['userOspId'],
  data () {
    return {
      form: {},
      key: '',
      loading: false,
      availableRoles: [],
      userRoles: []
    }
  },
  computed: {
    getAlertText () {
      if (this.$route.name === 'AppUserViewRoles') {
        return this.$t('user_management.view_app_user_page.roles.alert_text')
      }
      return this.$t('admin_management.view_admin_page.roles.alert_text')
    }
  },
  methods: {
    async fetchData () {
      this.loading = true
      try {
        await Promise.all([
          this.fetchAvailableRoles(),
          this.fetchUserRoles()
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    fetchAvailableRoles () {
      return this.$api.user.roleList(this.userOspId, {
        onSuccess: response => {
          this.setAvailableRoles(response)
        }
      })
    },
    fetchUserRoles () {
      return this.$api.user.getUser(this.userOspId, {
        onSuccess: response => {
          this.setUserRoles(response)
        }
      })
    },
    async transfer (direction) {
      this.loading = true
      try {
        if (direction === 'right') {
          const roles = get(this.$refs, ['transfer', 'leftChecked'], []).slice()
          await userRepository.addRoles({ roles, userOspId: this.userOspId }, {
            successNotification: this.$t('admin_management.view_admin_page.roles.success_notification.add')
          })
        } else {
          const roles = get(this.$refs, ['transfer', 'rightChecked'], []).slice()
          await userRepository.removeRoles({ roles, userOspId: this.userOspId }, {
            successNotification: this.$t('admin_management.view_admin_page.roles.success_notification.remove')
          })
        }
      } catch (e) {
        console.error(e)
      }
      return this.fetchData()
    },
    setAvailableRoles (roles) {
      this.availableRoles = roles.map(v => {
        return {
          key: v.role,
          label: this.$trans(v.label)
        }
      })
    },
    setUserRoles (user) {
      // console.log(user)
      this.userRoles = user.userData.roles
    },
    filterMethod (query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    }
  },
  async mounted () {
    this.$nextTick().then(() => {
      this.fetchData()
    })
  }
}
</script>

<style lang="scss">
.view-admin__alert {
  .el-alert__description {
    ul {
      margin: 0;
      list-style-type: none;

      li {
        text-indent: -22px;

        &:before {
          content: "-";
          margin-right: 5px;
        }
      }
    }
  }
}

</style>
